import React from 'react';
import './ethicAndCompliance.scss';

const EthicAndCompliance = () => (
  <div className="EthicAndCompliance">
    <p className="EthicAndCompliance-description">
      En ¡QuéBueno!, tenemos la firme convicción de que la cultura ética son elementos clave en el
      desarrollo de nuestra actividad. Contamos con un Código Ético, aplicable a todos los
      empleados, que recoge los principios de actuación y el comportamiento ético que se espera de
      todos nosotros en nuestras relaciones internas y externas. Disponemos también de una serie de
      políticas y procedimientos que desarrollan los principios recogidos en el Código Ético, tales
      como la Política Anticorrupción, o el Protocoló contra el acoso y de garantía integral de la
      libertad sexual.
    </p>
    <p>Consulta nuestros códigos, protocolos y políticas:</p>
    <ul>
      <li className="EthicAndCompliance-link">
        <a class="Link" href="/files/CODIGO ETICO.pdf" rel="noopener noreferrer" target="_blank">
          Código ético
        </a>
      </li>
      <li className="EthicAndCompliance-link">
        <a
          class="Link"
          href="/files/POLITICA ANTICORRUPCION.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Política Anticorrupción y de Prevención del Tráfico de Influencias
        </a>
      </li>
      <li className="EthicAndCompliance-link">
        <a
          class="Link"
          href="/files/PROTOCOLO CONTRA EL ACOSO Y DE GARANTÍA INTEGRAL DE LA LIBERTAD SEXUAL.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Protocolo contra el acoso y de garantía integral de la libertad sexual
        </a>
      </li>
    </ul>
  </div>
);

export default EthicAndCompliance;
