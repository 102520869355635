import React from 'react';
import ContentPageLayout from '../components/ContentPageLayout';
import EthicAndCompliance from '../components/EthicAndCompliance';

const Page = () => (
  <ContentPageLayout title="Ética y cumplimiento">
    <EthicAndCompliance />
  </ContentPageLayout>
);

export default Page;
